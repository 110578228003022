<template>
  <div style="padding:20px">
              <div v-loading="companyLoading" element-loading-text="拼命加载中">
                  <h4>企业样本组：</h4>
                  <div>
                      <el-radio-group v-model="params.sample" @change="sampleChange">
                          <el-radio
                              v-for="(item, index) in sampleList"
                              :key="index"
                              :label="item.name"
                              style="margin-bottom: 10px"
                              >{{ item.name }}
                              <span v-if="item.score"
                                  >（{{ item.score }}）</span
                              ></el-radio
                          >
                      </el-radio-group>
                  </div>
              </div>
              <div  v-loading="cityLoading" element-loading-text="拼命加载中">
                  <h4>城市：</h4>
                  <div>
                      <el-radio-group v-model="params.city" @change="cityChange">
                          <el-radio
                              v-for="(item, index) in cityList"
                              :key="index"
                              :label="item.name"
                              style="margin-bottom: 10px; width: 100%"
                              >{{ item.name }}
                              <span v-if="item.score"
                                  >（{{ item.score }}）</span
                              ></el-radio
                          >
                      </el-radio-group>
                  </div>
              </div>
              <div  v-loading="jobLoading" element-loading-text="拼命加载中">
                  <h4>填报人职务统计：</h4>
                  <div>
                      <el-radio-group v-model="params.job" @change="jobChange">
                          <el-radio
                              v-for="(item, index) in jobList"
                              :key="index"
                              :label="item.name"
                              style="margin-bottom: 10px; width: 100%"
                              >{{ item.name }}
                              <span v-if="item.score"
                                  >（{{ item.score }}）</span
                              ></el-radio
                          >
                      </el-radio-group>
                  </div>
              </div>
       
  </div>
</template>
<script>

import {
  surveyCompanyStatisticsApi,
  surveyCityStatisticsApi,
  surveyJobStatisticsApi,
} from '@/api/analysisResultApi'
export default {
//   props:{
//     id:{
//       type:Number
//     }
//   },
props:['id'],
  data() {
      return {
          sampleList: [],
          cityList: [],
          jobList: [],
          params: {
              sample: '全部',
              city: '全部',
              job: '全部',
          },
        companyLoading:false,
          cityLoading:false,
          jobLoading:false
       
      }
  },
  watch:{
    id(){
        this.companyLoading=true
        this.cityLoading=true
        this.jobLoading=true
        this.jobDateFun()     // 职务
        this.cityDateFun()    // 城市
        this.sampleDateFun()  // 样本企业
    },
  },
  async created() {
     if(this.id){
        this.companyLoading=true
        this.cityLoading=true
        this.jobLoading=true
        this.jobDateFun()     // 职务
        this.cityDateFun()    // 城市
        this.sampleDateFun()  // 样本企业
     }
  },
  mounted() {
    
  },
  computed: {
    
  },
  methods: {
     
      // 点击职位
      jobChange(){
          // this.getSurveyScoreFun()
          this.$emit('search', this.params)
      },
       // 点击样本企业
      sampleChange(){
          // this.getSurveyScoreFun()
          this.$emit('search', this.params)
      },
       // 点击城市
      cityChange(){
          // this.getSurveyScoreFun()
          this.$emit('search', this.params)
      },
      // 获取企业样本组
      async sampleDateFun() {
          var res = await surveyCompanyStatisticsApi()
          this.sampleList = [
              {
                  name: '全部',
              },
          ]
          this.sampleList.push(...res)
          this.companyLoading=false
      },
      // 获取城市
      async cityDateFun() {
          var res = await surveyCityStatisticsApi(this.id)
          this.cityList = [
              {
                  name: '全部',
              },
          ]
          this.cityList.push(...res)
          this.cityLoading=false
      },
      // 填报人职务数据
      async jobDateFun() {
          var res = await surveyJobStatisticsApi(this.id)
          this.jobList = [
              {
                  name: '全部',
              },
          ]
          this.jobList.push(...res)
          this.jobLoading=false
      },
  },
}
</script>
<style scoped>
</style>
