<template>
    <div>
        <el-dialog
            title="问卷信息"
            :visible.sync="dialogVisible"
            width="50%"
            style="height:80vh;"
            class="dialog-box"
            :before-close="handleClose"
        >
            <div class="dialog-content"  >
                <el-table :data="tableData" style="width: 100%" height="450px" border>
                    <el-table-column type="index" label="编号" width="50">
                    </el-table-column>
                    <el-table-column
                        prop="wjdo_title"
                        label="问卷名称"
                        width="300"
                    >
                    </el-table-column>
                    <el-table-column prop="time" label="填报时间">
                    </el-table-column>
                </el-table>
                <Pagination
                v-bind:child-msg="pageparm"
                @callFather="callFather"
            ></Pagination>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="dialogVisible = false"
                    >确 定</el-button
                >
            </span>
        </el-dialog>
    </div>
</template>
<script>
    import Pagination from '@/components/Pagination'
import { getWjListByUsccApi } from '@/api/analysisResultApi.js'
export default {
    // 注册组件
    components: {
        Pagination,
    },
    data() {
        return {
            dialogVisible: false,
            apiName: '',
            code: '',
            tableData: [],
            pageparm: {
                currentPage: 1,
                pageSize: 10,
                total: 20,
            },
            uscc:''
        }
    },
    methods: {
        open(value) {
            this.uscc = value
            this.getWjListByUsccFun()
            this.dialogVisible = true
        },
        // 企业填报问卷数据
        async getWjListByUsccFun(currentPage, pageSize) {
            var start = currentPage ? currentPage * pageSize - pageSize : 0
            var params = {
                start: start,
                length: 10,
                draw: 1,
                orderStr: 'time desc',
                uscc:this.uscc,
            }
            var res = await getWjListByUsccApi(params)
            this.tableData = res.data
            this.pageparm.total = res.recordsTotal
        },
        // 分页插件事件
        callFather(parm) {
            this.getWjListByUsccFun(parm.currentPage, parm.pageSize)
        },
        handleClose() {
            this.dialogVisible = false
        },
    },
}
</script>
<style scoped>
.dialog-box{
  overflow: hidden;
}
</style>
